html,
body {
    height: 100%;
}
body {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.57142857;
    color: #76838f;
    background-color: #f1f4f5;
}
h1, h2, h3, h4, h5, h6 {
    text-shadow: rgba(0,0,0,.15) 0 0 1px;
    color: $dark-blue;
}
ul {
    list-style: none;
}
a {
    &:hover {
        text-decoration: none;
    }
}
.form-control {
    border-radius: 0;
    &:focus {
        box-shadow: none;
        border-color: #ced4da;
    }
}
.btn {
    border-radius: 0;
    &:hover {
        cursor: pointer;
    }
    &:focus {
        box-shadow: none;
    }
}
.displayNone {
    display: none;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(53, 52, 52, 0.4);
    overflow: hidden;
}
.content {
    padding-bottom: 50px;
}
.page {
    margin-bottom: 30px;
}
.page_header {
    padding: 30px 0;
    .breadcrumb {
        padding: 0;
        margin: 0;
        background: transparent;
        li {
            +li {
                &:before {
                    padding: 0 5px;
                    color: #62a8ea;
                    content: "/\00a0";
                }
            }
        }
    }
    .page_title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 28px;
        padding: 0;
        color: $grey;
    }
}

.page_content {
    padding: 20px 30px;
    background: #fff;
    box-shadow: $box-shadow;
    margin-bottom: 20px;
}

.widget {
    background: #fff;
    box-shadow: $box-shadow;
    .widget_header {
        display: table;
        width: 100%;
        padding: 0 20px;
        height: 60px;
        border-bottom: 1px solid #ebedf2;
        .widget_header_title {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            h3 {
                display: table-cell;
                vertical-align: middle;
                font-size: 17px;
                font-weight: 500;
                color: $grey;
                @media (max-width: 1199.98px) {
                    font-size: 13px;
                }
            }
        }
        .widget_header_tools {
            text-align: right;
            display: table-cell;
            vertical-align: middle;
        }
    }
    .widget_body {
        padding: 15px 20px;
    }
}

.widget_progress {
    .progress_header {
        padding: 20px 25px;
        .wp__left {
            float: left;
            span {
                display: block;
            }
            .title {
                font-size: 17px;
                font-weight: 600;
            }
            .subtitle {
                font-size: 14px;
                font-weight: 300;
            }
        }
        .wp__right {
            float: right;
            span {
                display: block;
                font-size: 25px;
                font-weight: 600;
                color: $blue;
            }
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
    .progress {
        height: 7px;
    }
    .range {
        text-align: right;
        span {
            font-weight: 500;
            font-style: italic;
        }
    }
}

.accordion {
    .accordion_item {
        overflow: hidden;
        border-radius: 6px;
        .accordion_item_head {
            display: table;
            padding: 10px 20px;
            margin-bottom: 5px;
            width: 100%;
            overflow: hidden;
            background-color: #f7f8fa;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            &:hover {
                cursor: pointer;
            }
            .accordion_item_title {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
            .accordion_item_mode {
                &:before {
                    content: "\f180";
                    font-family: 'Web Icons';
                    font-size: 10px;
                }
            }
            &.collapsed {
                .accordion_item_mode {
                    &:before {
                        content: "\f181";
                        font-family: 'Web Icons';
                        font-size: 10px;
                    }
                }
            }
        }
        .accordion_item_body {
            .accordion_item_content {
                padding: 10px 20px;
            }
        }
    }
}

.vehicle_selector, .platenumber_selector, .reference_selector, .vin_selector {
    h3{
        font-size: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: $blue-grey;
    }
}

.vin_vehicles {
    width: 600px;
    position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    padding: 20px;
    background: #fff;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    .vin__header {
        i {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 25px;
            color: $dark-blue;
            cursor: pointer;
        }
    }
    .vin__content {
        height: 350px;
        overflow-y: auto;
        .vin_vehicle {
            padding: 10px 5px;
            color: $dark-blue;
            border-bottom: 1px solid #efefef;
            cursor: pointer;
            &:hover {
                background: #efefef
            }
        }
    }

}

.platenumber_selector {
    .platenumber {
        position: relative;
        background: #0052b4;
        padding: 1px 40px 1px 50px;
        &:before {
            content: " ";
            width: 30px;
            height: 25px;
            position: absolute;
            top: 0px;
            left: 10px;
            //background: url('/img/icons/euro.png') no-repeat left top;
            background-size: 100%;
        }
        &:after {
            content: "F";
            position: absolute;
            display: block;
            top: 22px;
            left: 20px;
            font-size: 18px;
            color: #fff;
            font-weight: 500;
        }
    }
    input.form-control {
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
        font-weight: 600;
        border: none;
        height: 46px;
        font-size: 25px;
    }
}

.help {
    i {
        font-size: 20px;
        color: $orange;
    }
    span {
        font-size: 12px;
        color: $grey;
        opacity: .6;
    }
}

.app_menu {
    height: 80px;
    .menu_items {
        text-align: center;
        ul {
            margin: 10px 0;
            padding: 0;
            li {
                width: 130px;
                display: inline-block;
                text-align: center;
                a {
                    display: block;
                    color: #34495e;
                    padding: 5px;
                    opacity: .5;
                    i {
                        font-size: 40px;
                        line-height: 40px;
                        display: block;
                        margin: 5px;
                    }
                    &:hover {
                        color: lighten($teal, 5%);
                        opacity: 1;
                    }
                }
                &.active {
                    a {
                        color: lighten($teal, 5%);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.site-menubar {
    position: fixed;
    top: 146px;
    z-index: 1400;
    width: 260px;
    height: 100%;
    height: -webkit-calc(100% - 66px);
    height: calc(100% - 66px);
    font-family: Roboto,sans-serif;
    color: rgba(163,175,183,.9);
    background: #263238;
    overflow-y: auto;
    .site-menubar-body {
        height: 100%;
        height: -webkit-calc(100% - 76px);
        height:         calc(100% - 76px);
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    a {
        outline: none;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    .site-menu {
        padding-bottom: 20px;
        overflow-y: hidden;
        font-size: 14px;

        .site-menu-item {
            position: relative;
            white-space: nowrap;
            > a {
                white-space: nowrap;
                cursor: pointer;
                .site-menu-icon {
                    display: inline-block;
                    width: 1em;
                    text-align: center;
                }
                .site-menu-title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: middle;
                }
            }
            a {
                display: block;
                color: rgba(163, 175, 183, .9);
                padding: 0 20px;
                line-height: 30px;
            }
        }
        > .site-menu-item {
            padding: 0;
            &.open {
                background: #242f35;
                > a {
                    color: #fff;
                    background: inherit;
                }
            }
            &.hover, &:hover {
                color: rgba(255, 255, 255, .8);
                background-color: rgba(255, 255, 255, .02);
            }
            &.active {
                background: #242f35;
                border-top: 1px solid rgba(0, 0, 0, .04);
                border-bottom: 1px solid rgba(0, 0, 0, .04);
                > a {
                    color: #fff;
                    background: inherit;
                }
                &.hover > a {
                    background: inherit;
                }
            }
        }
        .site-menu-sub {
            .site-menu-item {
                line-height: 36px;
                color: rgba(163, 175, 183, .9);
                background: transparent;
                &.has-sub {
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    &.open {
                        border-top-color: rgba(0, 0, 0, .06);
                        border-bottom-color: rgba(0, 0, 0, .06);
                    }
                }
                &.open {
                    background: rgba(0, 0, 0, .06);
                    > a {
                        color: #fff;
                    }
                }
            }
            > a {
                padding: 0 30px;
            }
            &.hover > a, &:hover > a {
                color: rgba(255, 255, 255, .8);
                background-color: rgba(255, 255, 255, .02);
            }
            &.active {
                background: rgba(0, 0, 0, .06);
                > a {
                    color: #fff;
                }
                &.hover > a {
                    background-color: transparent;
                }
            }
        }
    }
}

#app-navbar-search {
    background: #fff;
    width: 100%;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 66px;
}

.spinner {
    content: '';
    display: none;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.7rem;
    margin-left: -0.7rem;
    border-top: 2px solid $dark-blue;
    border-right: 2px solid transparent;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: spinner-rotate 0.6s linear infinite;
    -moz-animation: spinner-rotate 0.6s linear infinite;
    -ms-animation: spinner-rotate 0.6s linear infinite;
    -o-animation: spinner-rotate 0.6s linear infinite;
    animation: spinner-rotate 0.6s linear infinite;
    &.s_right {
        left: auto;
        right: 0.7rem;
    }
    &.show {
        display: block !important;
    }
}

.vehicle_information {
    color: $dark-blue;
    header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .brand_img {
            img {
                width: 60px;
            }
        }
        .car_name {
            padding-left: 10px;
            span {
                display: block;
                font-size: 11px;
                font-weight: 500;
            }
            .vehicle_name {
                font-size: 16px;
                font-weight: 600;
            }
        }

    }
    .vehicle_model {
        > div {
            border-bottom: 1px solid #efefef;
            padding: 5px 0;
            font-size: 12px;
            .vm__title {
                width: 85px;
                display: inline-block;
            }
            .vm__value {
                font-weight: 600;
            }
        }
    }
}

.widget {
    .components {
        margin: 10px 0;
        padding: 0;
        li {
            padding: 7px 0;
            border-bottom: 1px solid $border-color;
            .component {
                display: flex;
                align-items: center;
                color: $blue-grey;
                transition: .3s color;
                img {
                    width: 35px;
                    filter: grayscale(100%);
                    -webkit-filter: grayscale(100%);
                    -moz-filter: grayscale(100%);
                    -ms-filter: grayscale(100%);
                    -o-filter: grayscale(100%);
                    transition: .3s;
                }
                span {
                    display: block;
                    padding-top: 8px;
                    margin-left: 10px;
                    font-size: 14px;
                }
                &:hover {
                    color: $blue;
                    img {
                        filter: grayscale(0%);
                        -webkit-filter: grayscale(0%);
                        -moz-filter: grayscale(0%);
                        -ms-filter: grayscale(0%);
                        -o-filter: grayscale(0%);
                    }
                }
                &.disabled {
                    opacity: .5;
                    color: inherit;
                    img {
                        filter: grayscale(100%);
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        -o-filter: grayscale(100%);
                    }
                }

            }
        }
        &:first-child {
            border-top: 1px solid $border-color;
        }
    }
}

.message {
    display: block;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid transparent;
    &.error {
        border-color: $red;
        color: $red;
    }
}

$md-checkbox-margin: 10px 0;
$md-checkbox-checked-color: rgb(51, 122, 183);
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-size: 20px;
$md-checkbox-padding: 4px;

$md-checkmark-width: 2px;
$md-checkmark-color: #fff;

.md-checkbox {
    position: relative;
    margin: $md-checkbox-margin;
    text-align: left;

    &.md-checkbox-inline {
        display: inline-block;
    }

    label {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        &:before, &:after {
            content: "";
            position: absolute;
            left:0;
            top: 0;
        }

        &:before {
            // box
            width: $md-checkbox-size;
            height: $md-checkbox-size;
            background: #fff;
            border: 2px solid $md-checkbox-border-color;
            border-radius: 2px;
            cursor: pointer;
            transition: background .3s;
        }

        &:after {
            // checkmark
        }
    }

    input[type="checkbox"] {
        outline: 0;
        margin-right: $md-checkbox-size - 10px;
        visibility: hidden;

        &:checked {
            + label:before{
                background: $md-checkbox-checked-color;
                border:none;
            }
            + label:after {

                $md-checkmark-size: $md-checkbox-size - 2*$md-checkbox-padding;

                transform: rotate(-45deg);

                top: ($md-checkbox-size / 2) - ($md-checkmark-size / 4) - $md-checkbox-size/10;
                left: $md-checkbox-padding;
                width: $md-checkmark-size;
                height: $md-checkmark-size / 2;

                border: $md-checkmark-width solid $md-checkmark-color;
                border-top-style: none;
                border-right-style: none;
            }
        }

        &:disabled {
            + label:before{
                border-color: $md-checkbox-border-color-disabled;
            }
            &:checked {
                + label:before{
                    background: $md-checkbox-checked-color-disabled;
                }
            }
        }
    }
}

.md-radio {
    margin: 16px 0;

    &.md-radio-inline {
        display: inline-block;
    }

    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $md-radio-checked-color;
            animation: ripple 0.2s linear forwards;
        }
        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 0 ($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        &:before, &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $md-radio-border-color;
        }
        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }
}

.input_error {
    ul {
        margin: 0;
        padding: 0;
        li {
            color: $red;
            font-size: 12px;
        }
    }
}

.user_form {
    input, select {
        max-width: 300px;
        font-size: 14px;
    }
}

.form-group {
    label {
        display: inline-block;
        margin-bottom: 3px;
        font-weight: 400;
        font-size: 13px;
        color: $blue-grey;
    }
}

.form-control{
    font-size: 14px;
    &:focus {
        border: 1px solid $blue;
    }
}

.nav-tabs {
    background: #fff;
    border-bottom: 0px;
    .nav-item {
        padding: 5px 10px;
        .nav-link {
            color: $dark-blue;
            font-size: 14px;
            border: none;
            font-weight: 600;
            &.active {
                color: $blue;
                border-bottom: 2px solid $blue;
            }
            &:hover {
                border: none;
                color: $blue;
                border-bottom: 2px solid $blue;
            }
        }
    }
}


.toast_container {
    position: fixed;
    top: 75px;
    right: 30px;
    width: 300px;
    z-index: 1300;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    .toast {
        padding: 20px;
        color: #fff;
        font-weight: 400;
        border-radius: 2px;
        box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.6);
        &.toast_success {
            background-color: $teal;
        }
        &.toast_error {
            background-color: $red;
        }
    }
}

.select2-container {
    .select2-selection--single {
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0px;
        height: 37px;
        .select2-selection__rendered {
            line-height: 37px;
        }
        .select2-selection__arrow {
            height: 37px;
        }
    }
    &:focus {
        outline: none;
        border: 1px solid #3583CA;
    }
}

ul#select2-select_manufacturer-results {
    max-height: 300px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0;
    a {
        &:hover {
            opacity: .5;
        }
    }
    &:hover {
        border: 1px solid transparent;
        background: none;
    }
}

.btn {
    cursor: pointer;
    border-radius: 3px;
}

.btn-pill {
    border-radius: 60px;
}

.btn-air {
    box-shadow: 0px 5px 10px 2px rgba(88, 103, 221, 0.19);
}

.btn-circle {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 10px 0;
    i {
        font-size: 23px;
    }
}

.table {
    thead {
        background-color: #efefef
    }
}

.manual span {
    font-family: $font-family !important;
    color: $dark-blue !important;
}

.graphic_search {
    text-align: center;
    position: relative;
    svg {
        width: 800px !important;
        height: 600px;
    }
    table {
        font-family: $font-family !important;
        .button {
            width: 100%;
            padding: 10px;
            background: $teal;
            font-size: 12px;
            text-align: center;
            border: 1px solid transparent;
            color: #fff;
            cursor: pointer;
            font-family: $font-family !important;
            &:hover {
                background: transparent;
                border-color: $teal;
                color: $teal;
            }
        }
        .buttonhover {
            width: 100%;
            padding: 10px;
            font-size: 12px;
            text-align: center;
            background: transparent;
            border-color: $teal;
            color: $teal;
        }
    }
    #toolTip {
        background: transparent !important;
        width: 250px;
        color: $teal !important;
        font-weight: 600;
        font-family: $font-family !important;
        padding: 5px !important;
        border: none !important;
        top: 30px !important;
        left: 0 !important;
        right: 0 !important;
        margin: auto;
    }
}

.upload {
    input {
        position: absolute;
        margin-top: 3px;
        margin-left: 3px;
        height: 1px;
        width: 1px;
        z-index: -5;
    }
    .upload__button {
        max-width: 80%;
        font-size: 14px;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        color: #fff;
        background-color: $teal;
        padding: 0.625rem 1.25rem;
        span {
            padding: 5px;
        }
    }
    svg {
        fill: #fff;
    }
}

.overflowHidden {
    overflow: hidden;
}

.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 23px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    &.round {
        border-radius: 33px;
    }
    &.round:before {
        border-radius: 50%;
    }
}

input:checked + .slider {
    background-color: #007bff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.manual {
    margin-top: 30px;
    display: none;
    .print {
        text-align: right;
    }
}
.dialog {
    width: 320px;
    height: 300px;
    background: #fff;
    padding: 10px 20px;
    overflow-y: auto;
    transition: all 0.3s ease;
    transform: scale(0);
    transform-origin: 0 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
    margin: auto;
    box-shadow: $box-shadow;
    .dialog__title {
        font-size: 15px;
        font-weight: 600;
        padding: 10px 3px 20px;
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            padding: 2px 5px;
            cursor: pointer;
            display: none;
            &:hover {
                background: #f1f4f5;
            }
        }
    }
    &.open {
        transform: scale(1);
    }
}

.dialog_modules {
    max-width: 800px;
    background: #fff;
    padding: 10px 20px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 20px;
    right: 0;
    z-index: 1000;
    margin: auto;
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    .dialog__header {
        position: relative;
        .dialog__title {
            color: $dark-blue;
        }
        .dialog__close {
            position: absolute;
            top: -4px;
            right: 5px;
            font-size: 20px;
            font-weight: bold;
            color: $dark-blue;
            cursor: pointer;
        }
    }
    .links {
        padding: 20px;
        h5 {
            font-size: 16px;
        }
        a {
            display: block;
            font-size: 13px;
        }
    }
}

.b11 {
    cursor: pointer;
}

.categories_filter {
    max-width: 500px;
    margin: auto;
    position: relative;
    .filtered_categories {
        position: absolute;
        border: 1px solid #ced4da;
        border-top: 0;
        width: 100%;
        top: 37px;
        padding: 15px 20px;
        background: #fff;
        max-height: 350px;
        overflow-y: auto;
        z-index: 9;
        box-shadow: $box-shadow;
        display: none;
        a {
            display: block;
            margin-top: 5px;
        }
    }
}

.w-900 {
    max-width: 900px !important;
}

.table th, .table td {
    padding: 0.45rem 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead {
    background-color: #222;
    color: #fff;
}



