.super-menu {
    position: fixed;
    width: 280px;
    top: 132px;
    bottom: 45px;
    left: 0;
    z-index: 1000;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    background: $dark-blue;
    .menu-element {
        padding: 30px 15px;
        .menu-element-title {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.3px;
            opacity: .7;
        }
        .menu-element-list {
            list-style: none;
            margin: 15px 0;
            padding: 0;
            li {
                display: block;
                a {
                    padding: 10px 0;
                    display: block;
                    color: #a7a7a7;
                    i {
                        font-size: 18px;
                    }
                    span {
                        margin-left: 15px;
                    }
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
    }
}
