footer {
    margin-top: 20px;
    background: $dark-blue;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    img {
        width: 120px;
    }
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        li {
            padding-left: 15px;
            a {
                color: #fff;
                font-size: 12px;
                display: block;
                padding: 12px;
            }
        }
    }
}