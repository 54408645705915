.login-page {
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    .login {
        width: 100%;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        .login-bg {
            width: 100%;
            background: $dark-blue url(../../../../public/img/login-bg.jpg)  no-repeat center center;
        }
        .login-overlay {
            position: absolute;
            top: 0;
            right: 15px;
            background: $dark-blue;
            opacity: 0.7;
            width: 100%;
            z-index: 99;
            height: 100%;
        }
        .login-right {
            position: relative;
            height: 100vh;
            min-height: 100vh;
            max-height: 100vh;
            padding: 110px 5% 5%;
            border-radius: 4px;
            .login-title {
                text-align: center;
                img {
                    width: 200px;
                    margin: 20px;
                }
            }
            form {
                width: 50%;
                min-width: 300px;
                max-width: 480px;
                label {
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                .form-control {
                    border-radius: 0;
                    border-top: 0px;
                    border-left: 0px;
                    border-right: 0px;
                }

                .reset-password {
                    font-size: 13px;
                    text-align: right;
                }

                .login-submit {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }
    }
}