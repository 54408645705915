.navbar-fixed-top{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1001;
    box-shadow: 0 1px 15px 1px rgba(69,65,78,.6);
}
.app-navbar {
    min-height: 50px;
    padding: 0 20px;
    background: $dark-blue;
    flex-wrap: nowrap;
    &.light {
        border-top: 3px solid #8bcaad;
        background-color: #f1f4f5;
        .nav-link {
            i {
                color: $dark-blue;
            }
            .link-title {
                color: $dark-blue;
            }
        }
    }
    &.navbar-header {
        color: #fff;
    }
    .navbar-brand {
        img {
            width: 80px;
            @media (max-width: 992px) {
                width: 70px;
            }
        }
    }
    .right-bar-burger {
        position: absolute;
        right: 20px;
        display: none;
        @media (max-width: 992px) {
            display: block;
        }
    }
    .navbar-nav {
        &.custom {
            flex-direction: row;
            @media (max-width: 992px) {
                display: none;
            }
        }

        .nav-item {
            height: 100%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            padding: 0 10px;
            .nav-link {
                text-align: center;
                i {
                    font-size: 19px;
                }
                .link-title {
                    font-size: 12px;
                    display: block;
                    @media (max-width: 992px) {
                        display: none;
                    }
                }
            }
            &.selection {
                .nav-link {
                    padding: 0 !important;
                    .selection-container {
                        display: flex;
                        flex-direction: row;
                        .selection-logo {
                            padding: 5px 15px;
                            img {
                                height: 50px;
                            }
                        }
                        .selection-content {
                            padding: 9px 0;
                            text-align: left;
                            .selection-name {
                                font-weight: 600;
                            }
                            .selection-model {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
        .dropdown {
            color: #fff;
        }
        li {
            .nav-link {
                height: 100%;
                color: #fff;
                position: relative;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .cart, .quotation {
            .badge {
                position: absolute;
                top: 5px;
                right: -10px;
            }
            .dropdown-menu {
                width: 560px !important;
                .dropdown-item {
                    span {
                        font-size: 13px;
                        padding-top: 10px;
                        display: block;
                    }
                }
            }
        }
        .dropdown-menu {
            top: 60px;
            text-align: left;
            display: none;
            position: absolute;
            z-index: 1100;
            width: 245px;
            padding-top: 0;
            margin-top: 0;
            border: none;
            box-shadow: $box-shadow;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-transform-style: preserve-3d;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-radius: 2px;
            .dropdown-content {
                padding: 10px 20px;
                .dropdown-nav {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    .dropdown-item {
                        display: block;
                        .username
                        {
                            span {
                                margin: 0;
                                vertical-align: middle;
                                font-weight: 600;
                                font-size: 0.85rem;
                                text-transform: uppercase;
                            }
                        }
                        > a {
                            display: table;
                            table-layout: fixed;
                            width: 100%;
                            height: 100%;
                            text-decoration: none;
                            position: relative;
                            outline: none !important;
                            vertical-align: middle;
                            padding: 9px 0;
                            color: $dark-blue;
                            i {
                                display: table-cell;
                                height: 100%;
                                vertical-align: middle;
                                text-align: left;
                                width: 35px;
                                font-size: 1.4rem;
                                line-height: 0;
                            }
                            span {
                                display: table-cell;
                                height: 100%;
                                width: 100%;
                                margin: 0;
                                padding: 0;
                                vertical-align: middle;
                                font-size: 15px;
                            }
                            &:hover {
                                color: $blue;
                            }
                        }
                    }
                }
            }
            &.show {
                display: block;
                -webkit-animation: m-dropdown-fade-in .3s ease 1,m-dropdown-move-up .3s ease-out 1;
                -moz-animation: m-dropdown-fade-in .3s ease 1,m-dropdown-move-up .3s ease-out 1;
                -ms-animation: m-dropdown-fade-in .3s ease 1,m-dropdown-move-up .3s ease-out 1;
                -o-animation: m-dropdown-fade-in .3s ease 1,m-dropdown-move-up .3s ease-out 1;
                animation: m-dropdown-fade-in .3s ease 1,m-dropdown-move-up .3s ease-out 1;
            }
        }
        .dropdown-menu {
            left: inherit;
            right: 0;
        }
        .search {
            position: relative;
            padding: 0;
            min-width: 150px;
            max-width: 400px;
            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 38px;
                background: $blue-grey;
                height: 100%;
                border: none;
                color: #fff;
                cursor: pointer;
                i {
                    font-size: 18px;
                    vertical-align: middle;
                    margin-top: -2px;
                }
            }
            span {
                position: absolute;
                right: 50px;
                top: 5px;
                color: $blue-grey;
            }
            .spinner {
                top: 18px;
            }
            .search-input {

                border-radius: 2px;
            }
            .search-result {
                top: 45px;
                left: 0;
                text-align: left;
                display: none;
                width: 100%;
                position: absolute;
                z-index: 1100;
                border: none;
                box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
                -webkit-transform: translateZ(0);
                -moz-transform: translateZ(0);
                -ms-transform: translateZ(0);
                -o-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-transform-style: preserve-3d;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                border-radius: 2px;
                background: #fff;
                max-height: 300px;
                padding: 10px 20px;
                overflow-y: auto;
                .search-result-list {
                    a {
                        font-size: 12px;
                        padding: 10px 0;
                        display: block;
                    }
                }
            }
        }
    }
}

.header {
    background: #34495e;
    height: 65px;
    padding: 10px;
    border-top: 2px solid #8bcaad;
    border-bottom: 1px solid #ccc;
    .logo {
        a {
            color: #fff;
            font-size: 30px;
            font-weight: 600;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .user-info {
        text-align: right;
        padding: 8px;
        a {
            color: #fff;
            -webkit-transition: 0.3s;
            -moz-transition: 0.3s;
            -ms-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            &:hover {
                color: #cdcdcd;
                text-decoration: none;
            }
        }
    }
}

.right-bar {
    background: $dark-blue;
    -webkit-box-shadow: 0 0 24px 0 rgba(71,77,86,.1), 0 1px 0 0 rgba(71,77,86,.08);
    box-shadow: 0 0 24px 0 rgba(71,77,86,.1), 0 1px 0 0 rgba(71,77,86,.08);
    display: block;
    position: fixed;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: 280px;
    z-index: 9999;
    float: right!important;
    right: -290px;
    top: 0;
    bottom: 0;
    padding-bottom: 80px;
    &.show {
        right: 0;
    }
    .right-bar-title {
        height: 66px;
        padding: 0 20px;
        border-top: 2px solid #8bcaad;
        background-color: #f1f4f5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right-bar-toggle {
            font-size: 25px;
            color: $dark-blue;
        }
    }
    .right-bar-content {
        padding: 20px;
        .right-bar-menu {
            padding: 0;
            .menu-item {
                padding: 10px 5px;
                border-bottom: 1px solid lighten($dark-blue, 10%);
                &.selection {
                    .menu-link {
                        padding: 0 !important;
                        .selection-container {
                            display: flex;
                            flex-direction: row;
                            .selection-logo {
                                img {
                                    height: 40px;
                                }
                            }
                            .selection-content {
                                padding-left: 5px;
                                text-align: left;
                                .selection-name {
                                    font-weight: 600;
                                }
                                .selection-model {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
                .menu-link {
                    color: #fff;
                }
            }
        }
    }
}
