@-webkit-keyframes m-dropdown-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes m-dropdown-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes m-dropdown-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes m-dropdown-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes m-dropdown-move-up {
    from {
        margin-top: 10px;
    }
    to {
        margin-top: 0;
    }
}
@-moz-keyframes m-dropdown-move-up {
    from {
        margin-top: 10px;
    }
    to {
        margin-top: 0;
    }
}
@-o-keyframes m-dropdown-move-up {
    from {
        margin-top: 10px;
    }
    to {
        margin-top: 0;
    }
}
@keyframes m-dropdown-move-up {
    from {
        margin-top: 10px;
    }
    to {
        margin-top: 0;
    }
}

@-webkit-keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes spinner-rotate {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translateY(-150px);
        transform: translateY(-150px);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInRightBig {
    from {
        opacity: 0;
        -webkit-transform: translateY(-150px);
        transform: translateY(-150px);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }
    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}

@-moz-keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }
    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }
    50% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
    }
}
